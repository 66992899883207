import React from 'react'
import { Link } from 'gatsby'
import website from '../../assets/images/services/service-website-black.png';
import mobileApp from '../../assets/images/services/service-mobile-application.png';
import cloud from '../../assets/images/services/service-cloud-black.png';
import seo from '../../assets/images/services/service-seo-black.png';



const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={website} width="30%" alt="développement web" />
                            </div>
                            <h3>
                                <Link to="/service-web">
                                    Création de site web
                                </Link>
                            </h3>
                            <p>Votre site web est la vitrine de votre entreprise, il est important d'en considérer son importance. Nous développerons votre site web ou webshop sur mesure.</p>

                            <Link className="view-details-btn" to="/service-web">
                                En savoir plus
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={mobileApp} width="30%" alt="Développement application mobile" />
                            </div>

                            <h3>
                                <Link to="/service-mobile">
                                    Développement d'application
                                </Link>
                            </h3>

                            <p>Passez au numérique en offrant à vos employés ou clients une expérience supérieure avec une application mobile Android et iOS.</p>

                            <Link className="view-details-btn" to="/service-mobile">
                                En savoir plus
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={seo} width="30%" alt="SEO Marketing" />
                            </div>

                            <h3>
                                <Link to="/service-seo">
                                    SEO & Marketing
                                </Link>
                            </h3>

                            <p>Envie d'améliorer votre présence en ligne ? Nous analysons votre site et l'optimisons afin d'améliorer son référencement sur Google. Touchez plus de clients!</p>

                            <Link className="view-details-btn" to="/service-seo">
                                En savoir plus
                            </Link>
                        </div>
                    </div>

                    {/* <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Research
                                </Link>
                            </h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>

                            <Link className="view-details-btn" to="/service-details">
                                En savoir plus
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Analytics
                                </Link>
                            </h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>

                            <Link className="view-details-btn" to="/service-details">
                                En savoir plus
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Technology
                                </Link>
                            </h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>

                            <Link className="view-details-btn" to="/service-details">
                                En savoir plus
                            </Link>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default ServicesOne